<template>
  <div>
    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :data="investmentActions.data"
        :columns="investmentActions.columns"
        :filters="investmentActions.filters"
        :loading="investmentActions.loading"
        :actions="investmentActions.actions"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/invest/admin/actions`"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in investmentActions.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
          <slot>

          </slot>
        </template>
      </datatable>
    </div>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10 pl-12"
      ref="investmentActionApprovalModal"
    >
      <h4 class="text-xl font-bold mb-6">Approve Investment Action</h4>
      <template v-if=getFormError(actionApprovalForm)>
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon ">!</span>
          <span>{{ getFormError(actionApprovalForm) }}</span>
        </div>
      </template>
      <div class="mb-6">Are you sure you want to continue?</div>
      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="$refs.investmentActionApprovalModal.close()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="reviewAction"
        >
          <sm-loader v-if="actionApprovalForm.loading" class="sm-loader-white" />
          <span v-else>Approve</span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actionId: undefined,
      actionApprovalForm: this.$options.basicForm([
        { name: "action_status", value: 'action_declined'},
      ]),
      currentFilter: null,
      investmentActions: this.$options.resource([], {
        actions: [
          {text: 'Approve', class: 'btn-green btn-sm', action: (action) => {
            this.actionApprovalForm.error = null;
            this.$refs.investmentActionApprovalModal.open();
            this.actionId = action.id;
            this.actionApprovalForm.data.action_status.value = 'action_approved';
          }},
          {text: 'Decline', class: 'btn-red btn-sm', action: (action) => {
            this.actionId = action.id;
            this.actionApprovalForm.data.action_status.value = 'action_declined';
            this.reviewAction();
          }}
        ],
        filters: [
          {name: 'investment_creation', 'title': 'Investment Creation'},
          {name: 'investment_extension', 'title': 'Investment Extension'},
          {name: 'investment_liquidation', 'title': 'Investment Liquidation'},
          {name: 'investment_rollover', 'title': 'Investment Rollover'},
          {name: 'action_pending', 'title': 'Pending Actions'},
          {name: 'action_approved', 'title': 'Approved Actions'},
          {name: 'action_declined', 'title': 'Declined Actions'}
        ],
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Action Id',
          },
          {
            name: 'admin_name',
            th: 'Admin Name',
            // render: investmentActions =>
            //   `${investmentActions?.user?.name} ${investmentActions?.user?.last_name || 'N/A'}`
          },
          {
            name: 'admin_email',
            th: 'Admin Email',
          },
          {
            name: 'user_name',
            th: 'User Name',
          },
          {
            name: 'user_email',
            th: 'User Email',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (investmentAction) =>
              investmentAction.amount ? `₦ ${this.$options.filters.currency(investmentAction.amount)}` : null,
          },
          {
            name: 'admin_approver_name',
            th: 'Aprrover Name',
          },
          {
            name: 'admin_approver_email',
            th: 'Approver Email',
          },
          {
            name: 'action_type',
            th: 'Action Type',
          },
          {
            name: 'action_status',
            th: 'Action Status',
            render: (_, action_status) =>
              this.investmentActionstatusCheck(action_status)
          },
          {
            name: 'investment_name',
            th: 'Investment Name',
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (_, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
        ],
      }),
    };
  },
  methods: {
    click(row) {
      window.open(
        this.$router.resolve({
          name: 'staff-view',
          params: {
            staffId: row?.user_id,
          },
        }).href,
        '_blank'
      );
    },

    reviewAction() {
      this.actionApprovalForm.loading = true;
      this.sendRequest('admin.invest.reviewInvestmentAction', this.actionId, {
          data: this.getFormData(this.actionApprovalForm),
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: `The action has successfully been ${this.actionApprovalForm.data.action_status.value === 'action_approved' ? ' approved' : ' declined'}`,
              button: 'Okay',
            });

            this.actionApprovalForm.loading = false;
            this.$emit('success');
            this.$refs.investmentActionApprovalModal.close();
            this.reloadTable();
          },
          error: (error) => {
            this.actionApprovalForm.loading = false;
            this.actionApprovalForm.error = error;
            // Because the modal doesn't pop up when declining an Investment action, a pop up needs to show
            if (this.actionApprovalForm.data.action_status.value === 'action_declined') {
              this.$error({
                title: 'Operation Error',
                body: error?.response?.data,
                button: 'Okay'
              });
            }
          }
        }
      )
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }
      this.$refs.table.clickedFilter(this.currentFilter);
    },
    investmentActionstatusCheck(status) {
      switch (status) {
        case 'action_declined':
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
        case 'action_pending':
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
        case 'action_approved':
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
              ${status}
            </div>`;
      }
    },
  },
};
</script>
